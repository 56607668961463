import React from "react"
import Layout from "../components/layout"
import '../components/about.scss';
import ali from '../assets/ali-pic.png';
import aboutPhoto from '../assets/ali-about-photo.png';

export default function Home() {
  return (
    <Layout>
      <section>
        <div className="row">
          <div className="page-width-md">
            <img className="about-photo" src={aboutPhoto} alt="Picture of Ali Felski" />
            <p className="callout callout-lg">With nearly 15 years of experience, I strive to find and solve complex problems, fighting for users and creating the best user experience for them.</p>
            <div className="margin-btm-md">
              <p>I graduated with a BFA in Graphic Design from the Savannah College of Art and Design and started off my career in print work. I quickly realized that my passion lay in web / product design, UX and UX research. Being able to understand what users want and need and being able to build it for them. I have worked for many different places: design agencies, SASS startups, non-profits, the Government, etc… and had a number of different roles, pure designer, UX, researcher, and manager / director. Throughout, I have been lucky enough to work with amazingly passionate people and bring my love and knowledge of UX with me, managing, mentoring and at times building entire UX practices into the companies I have had the pleasure of working for.</p>
              <p>I now reside outside of Denver, CO, consulting, freelancing for old clients such as the Red Cross and looking for the next set of problems to solve.</p>
              <p>Outside of work you can find me devouring books, running, hiking, skiing, illustrating and attempting to play the violin.</p>
            </div>
            <div className="margin-btm-lg">
              <h2 className="header--medium text-centered margin-btm-xs">Skills</h2>
              <div className="row-projects flex-break-sm">
                <div className="column column1 row-sm-md row-padding col-100">
                  <div className="list-item">
                    <span className="span-width-spacing">User Interface Design (e.g. sitemaps, wireframes, prototyping)</span>
                  </div>
                  <div className="list-item">
                    <span className="span-width-spacing">Visual Design</span>
                  </div>
                </div>
                <div className="column column2 row-sm-md row-padding col-100">
                  <div className="list-item">
                    <span className="span-width-spacing">User Research (e.g. facilitation of kickoff meetings, brainstorming, user interviews etc…)</span>
                  </div>
                  <div className="list-item">
                    <span className="span-width-spacing">Front-end Markup</span>
                  </div>
                </div>
                <div className="column column2 row-sm col-100">
                  <div className="list-item">
                    <span className="span-width-sm">Teaching</span>
                  </div>
                  <div className="list-item">
                    <span className="span-width-sm">Management</span>
                  </div>
                  <div className="list-item">
                    <span className="span-width-sm">Illustration</span>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <h2 className="header--medium text-centered margin-btm-xs">Proficient Programs</h2>
              <div className="row-projects flex-break-sm">
                <div className="column column1 row-sm-md row-padding col-100">
                  <div className="list-item">
                    <span className="span-width-spacing">Figma</span>
                  </div>
                  <div className="list-item">
                    <span className="span-width-spacing">Adobe Suite including some After Effects</span>
                  </div>
                </div>
                <div className="column column2 row-sm-md row-padding col-100">
                  <div className="list-item">
                    <span className="span-width-spacing">Sketch</span>
                  </div>
                  <div className="list-item">
                    <span className="span-width-spacing">Omnigraffle</span>
                  </div>
                </div>
                <div className="column column2 row-sm col-100">
                  <div className="list-item">
                    <span className="span-width-sm">Other prototyping tools such as Invision</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="bg-yellow fullwidth-cta">
        <div className="row">
          <div className="relative">
            <div className="cta">
              <div className="margin-btm-sm">
                <h2 className="header--large text-centered">Want to learn more?</h2>
                <p className="text-centered">Want to get more information or say hello? Reach out below, I'd love to hear from you!</p>
              </div>
              <a className="button button-group" href="mailto:alifelski@gmail.com">Say Hello!</a>
              <a className="button" target="_blank" href="https://www.linkedin.com/in/alexandra-felski-6626464/">View Resume</a>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
